const stationNames = [
  "1 Av L",
  "103 St - Corona Plaza 7",
  "103 St 1",
  "103 St 6",
  "103 St B-C",
  "104 St A",
  "104 St J",
  "110 St 6",
  "111 St 7",
  "111 St A",
  "111 St J",
  "116 St - Columbia University 1",
  "116 St 2-3",
  "116 St 6",
  "116 St B-C",
  "121 St J",
  "125 St 1",
  "125 St 2-3",
  "125 St 4-5-6",
  "125 St A-B-C-D",
  "135 St 2-3",
  "135 St B-C",
  "137 St - City College 1",
  "138 St - Grand Concourse 4-5",
  "14 St - Union Sq 4-5-6-N-Q-R-W",
  "14 St 1-2-3-F-M",
  "14 St A-C-E",
  "145 St 1",
  "145 St 3",
  "145 St A-B-C-D",
  "145 St B-D",
  "149 St - Grand Concourse 2-4-5",
  "15 St - Prospect Park F-G",
  "155 St C",
  "155 St D",
  "157 St 1",
  "161 St - Yankee Stadium 4-D",
  "163 St - Amsterdam Av C",
  "167 St 4",
  "167 St D",
  "168 St - Washington Hts 1",
  "168 St A-C",
  "169 St F",
  "170 St 4",
  "170 St D",
  "174 St 2-5",
  "174-175 Sts D",
  "175 St A",
  "176 St 4",
  "18 Av D",
  "18 Av F",
  "18 Av N",
  "18 St 1",
  "181 St 1",
  "181 St A",
  "182-183 Sts D",
  "183 St 4",
  "190 St A",
  "191 St 1",
  "2 Av F",
  "20 Av D",
  "20 Av N",
  "207 St 1",
  "21 St - Queensbridge F",
  "21 St G",
  "215 St 1",
  "219 St 2",
  "225 St 2",
  "23 St 1",
  "23 St 6",
  "23 St C-E",
  "23 St F-M",
  "23 St R-W",
  "231 St 1",
  "233 St 2",
  "238 St 1",
  "25 Av D",
  "25 St R",
  "28 St 1",
  "28 St 6",
  "28 St R-W",
  "3 Av - 138 St 6",
  "3 Av - 149 St 2-5",
  "3 Av L",
  "30 Av N-W",
  "33 St 6",
  "33 St 7",
  "34 St - 11 Av 7",
  "34 St - Herald Sq B-D-F-M-N-Q-R-W",
  "34 St - Penn Station 1-2-3",
  "34 St - Penn Station A-C-E",
  "36 Av N-W",
  "36 St D-N-R",
  "36 St M-R",
  "39 Av N-W",
  "4 Av F-G",
  "40 St 7",
  "42 St - Bryant Pk B-D-F-M",
  "42 St - Port Authority Bus Terminal A-C-E",
  "45 St N-R",
  "46 St 7",
  "46 St M-R",
  "47-50 Sts - Rockefeller Ctr B-D-F-M",
  "49 St N-R-W",
  "5 Av 7",
  "5 Av & 53 St E-M",
  "5 Av & 59 St N-R-W",
  "50 St 1",
  "50 St C-E",
  "50 St D",
  "51 St 6",
  "52 St 7",
  "53 St N-R",
  "55 St D",
  "57 St - 7 Av N-Q-R-W",
  "57 St F",
  "59 St - Columbus Circle 1-A-B-C-D",
  "59 St 4-5-6",
  "59 St N-R",
  "6 Av L",
  "62 St D",
  "63 Dr - Rego Park M-R",
  "65 St M-R",
  "66 St - Lincoln Center 1",
  "67 Av M-R",
  "68 St - Hunter College 6",
  "69 St 7",
  "7 Av B-D-E",
  "7 Av B-Q",
  "7 Av F-G",
  "71 St D",
  "72 St 1-2-3",
  "72 St B-C",
  "72 St Q",
  "74 St - Broadway 7",
  "75 Av F",
  "75 St J",
  "77 St 6",
  "77 St R",
  "79 St 1",
  "79 St D",
  "8 Av L",
  "8 Av N",
  "8 St - NYU R-W",
  "80 St A",
  "81 St - Museum of Natural History B-C",
  "82 St - Jackson Hts 7",
  "85 St - Forest Pkwy J",
  "86 St 1",
  "86 St 4-5-6",
  "86 St B-C",
  "86 St N",
  "86 St Q",
  "86 St R",
  "88 St A",
  "9 Av D",
  "9 St R",
  "90 St - Elmhurst Av 7",
  "96 St 1-2-3",
  "96 St 6",
  "96 St B-C",
  "96 St Q",
  "Alabama Av J",
  "Allerton Av 2",
  "Aqueduct - N Conduit Av A",
  "Astor Pl 6",
  "Astoria - Ditmars Blvd N-W",
  "Astoria Blvd N-W",
  "Atlantic Av - Barclays Ctr 2-3-4-5-B-D-N-Q-R",
  "Atlantic Av L",
  "Avenue H Q",
  "Avenue I F",
  "Avenue J Q",
  "Avenue M Q",
  "Avenue N F",
  "Avenue P F",
  "Avenue U F",
  "Avenue U N",
  "Avenue U Q",
  "Avenue X F",
  "Bay 50 St D",
  "Bay Pkwy D",
  "Bay Pkwy F",
  "Bay Pkwy N",
  "Bay Ridge - 95 St R",
  "Bay Ridge Av R",
  "Baychester Av 5",
  "Beach 105 St Rockaway Shuttle",
  "Beach 25 St A",
  "Beach 36 St A",
  "Beach 44 St A",
  "Beach 60 St A",
  "Beach 67 St A",
  "Beach 90 St Rockaway Shuttle",
  "Beach 98 St Rockaway Shuttle",
  "Bedford - Nostrand Avs G",
  "Bedford Av L",
  "Bedford Park Blvd - Lehman College 4",
  "Bedford Park Blvd D",
  "Bergen St 2-3",
  "Bergen St F-G",
  "Beverley Rd Q",
  "Beverly Rd 2-5",
  "Bleecker St 6",
  "Borough Hall 2-3-4-5",
  "Botanic Garden Franklin Av Shuttle",
  "Bowery J",
  "Bowling Green 4-5",
  "Briarwood - Van Wyck Blvd F",
  "Brighton Beach B-Q",
  "Broad Channel A-Rockaway Shuttle",
  "Broad St J",
  "Broadway G",
  "Broadway Jct A-C-J-L",
  "Broadway N-W",
  "Broadway-Lafayette St B-D-F-M",
  "Bronx Park East 2",
  "Brook Av 6",
  "Brooklyn Bridge - City Hall 4-5-6",
  "Buhre Av 6",
  "Burke Av 2",
  "Burnside Av 4",
  "Bushwick Av - Aberdeen St L",
  "Canal St 1",
  "Canal St 6-J-N-Q-R-W",
  "Canal St A-C-E",
  "Canarsie - Rockaway Pkwy L",
  "Carroll St F-G",
  "Castle Hill Av 6",
  "Cathedral Pkwy (110 St) B-C",
  "Cathedral Pkwy 1",
  "Central Av M",
  "Central Park North (110 St) 2-3",
  "Chambers St 1-2-3",
  "Chambers St A-C",
  "Chambers St J",
  "Chauncey St J",
  "Christopher St - Sheridan Sq 1",
  "Church Av 2-5",
  "Church Av B-Q",
  "Church Av F-G",
  "City Hall R-W",
  "Clark St 2-3",
  "Classon Av G",
  "Cleveland St J",
  "Clinton - Washington Avs C",
  "Clinton - Washington Avs G",
  "Coney Island - Stillwell Av D-F-N-Q",
  "Cortelyou Rd Q",
  "Cortlandt St R-W",
  "Court Sq 7-E-G-M",
  "Court Sq G",
  "Court St R",
  "Crescent St J",
  "Crown Hts - Utica Av 3-4",
  "Cypress Av 6",
  "Cypress Hills J",
  "DeKalb Av B-Q-R",
  "DeKalb Av L",
  "Delancey St F",
  "Ditmas Av F",
  "Dyckman St 1",
  "Dyckman St A",
  "E 105 St L",
  "E 143 St - St Mary's St 6",
  "E 149 St 6",
  "E 180 St 2-5",
  "East Broadway F",
  "Eastchester - Dyre Av 5",
  "Eastern Pkwy - Brooklyn Museum 2-3",
  "Elder Av 6",
  "Elmhurst Av M-R",
  "Essex St J-M",
  "Euclid Av A-C",
  "Far Rockaway - Mott Av A",
  "Flatbush Av - Brooklyn College 2-5",
  "Flushing - Main St 7",
  "Flushing Av G",
  "Flushing Av J-M",
  "Fordham Rd 4",
  "Fordham Rd D",
  "Forest Av M",
  "Forest Hills - 71 Av E-F-M-R",
  "Fort Hamilton Pkwy D",
  "Fort Hamilton Pkwy F-G",
  "Fort Hamilton Pkwy N",
  "Franklin Av 2-3-4-5",
  "Franklin Av C-Franklin Av Shuttle",
  "Franklin Av Franklin Av Shuttle",
  "Franklin St 1",
  "Freeman St 2-5",
  "Fresh Pond Rd M",
  "Fulton St 2-3-4-5-A-C-J",
  "Fulton St G",
  "Gates Av J",
  "Graham Av L",
  "Grand Army Plaza 2-3",
  "Grand Av - Newtown M-R",
  "Grand Central - 42 St 4-5-6-7-Grand Central Shuttle",
  "Grand Central - 42 St Grand Central Shuttle",
  "Grand St B-D",
  "Grand St L",
  "Grant Av A",
  "Greenpoint Av G",
  "Gun Hill Rd 2",
  "Gun Hill Rd 5",
  "Halsey St J",
  "Halsey St L",
  "Harlem - 148 St 3",
  "Hewes St J-M",
  "High St A-C",
  "Houston St 1",
  "Howard Beach - JFK Airport A",
  "Hoyt - Schermerhorn Sts A-C-G",
  "Hoyt St 2-3",
  "Hunters Point Av 7",
  "Hunts Point Av 6",
  "Intervale Av 2-5",
  "Inwood - 207 St A",
  "Jackson Av 2-5",
  "Jackson Hts - Roosevelt Av E-F-M-R",
  "Jamaica - 179 St F",
  "Jamaica - Van Wyck E",
  "Jamaica Center - Parsons & Archer E-J",
  "Jay St - MetroTech A-C-F-R",
  "Jefferson St L",
  "Junction Blvd 7",
  "Junius St 3",
  "Kew Gardens - Union Tpke E-F",
  "Kings Hwy B-Q",
  "Kings Hwy F",
  "Kings Hwy N",
  "Kingsbridge Rd 4",
  "Kingsbridge Rd D",
  "Kingston - Throop Avs C",
  "Kingston Av 3",
  "Knickerbocker Av M",
  "Kosciuszko St J",
  "Lafayette Av C",
  "Lexington Av & 53 St E-M",
  "Lexington Av & 59 St N-R-W",
  "Lexington Av & 63 St F-Q",
  "Liberty Av C",
  "Livonia Av L",
  "Longwood Av 6",
  "Lorimer St J-M",
  "Lorimer St L",
  "Marble Hill - 225 St 1",
  "Marcy Av J-M",
  "Metropolitan Av G",
  "Mets - Willets Point 7",
  "Middle Village - Metropolitan Av M",
  "Middletown Rd 6",
  "Montrose Av L",
  "Morgan Av L",
  "Morris Park 5",
  "Morrison Av- Sound View 6",
  "Mosholu Pkwy 4",
  "Mt Eden Av 4",
  "Myrtle - Willoughby Avs G",
  "Myrtle - Wyckoff Avs L-M",
  "Myrtle Av J-M",
  "Nassau Av G",
  "Neck Rd Q",
  "Neptune Av F",
  "Nereid Av 2",
  "Nevins St 2-3-4-5",
  "New Lots Av 3",
  "New Lots Av L",
  "New Utrecht Av N",
  "Newkirk Av 2-5",
  "Newkirk Plaza B-Q",
  "Northern Blvd M-R",
  "Norwood - 205 St D",
  "Norwood Av J",
  "Nostrand Av 3",
  "Nostrand Av A-C",
  "Ocean Pkwy Q",
  "Ozone Park - Lefferts Blvd A",
  "Park Pl 2-3",
  "Park Pl Franklin Av Shuttle",
  "Parkchester 6",
  "Parkside Av Q",
  "Parsons Blvd F",
  "Pelham Bay Park 6",
  "Pelham Pkwy 2",
  "Pelham Pkwy 5",
  "Pennsylvania Av 3",
  "President St 2-5",
  "Prince St R-W",
  "Prospect Av 2-5",
  "Prospect Av R",
  "Prospect Park B-Franklin Av Shuttle-Q",
  "Queens Plaza E-M-R",
  "Queensboro Plaza 7-N-W",
  "Ralph Av C",
  "Rector St 1",
  "Rector St R-W",
  "Rockaway Av 3",
  "Rockaway Av C",
  "Rockaway Blvd A",
  "Rockaway Park - Beach 116 St Rockaway Shuttle",
  "Roosevelt Island F",
  "Saratoga Av 3",
  "Seneca Av M",
  "Sheepshead Bay B-Q",
  "Shepherd Av C",
  "Simpson St 2-5",
  "Smith - 9 Sts F-G",
  "South Ferry 1",
  "Spring St 6",
  "Spring St C-E",
  "St Lawrence Av 6",
  "Steinway St M-R",
  "Sterling St 2-5",
  "Sutphin Blvd - Archer Av - JFK Airport E-J",
  "Sutphin Blvd F",
  "Sutter Av - Rutland Rd 3",
  "Sutter Av L",
  "Times Sq - 42 St 1-2-3-7-Grand Central Shuttle-N-Q-R-W",
  "Times Sq - 42 St Grand Central Shuttle",
  "Tremont Av D",
  "Union Sq - 14 St L",
  "Union St R",
  "Utica Av A-C",
  "Van Cortlandt Park - 242 St 1",
  "Van Siclen Av 3",
  "Van Siclen Av C",
  "Van Siclen Av J",
  "Vernon Blvd - Jackson Av 7",
  "W 4 St A-B-C-D-E-F-M",
  "W 8 St - NY Aquarium F-Q",
  "WTC Cortlandt 1",
  "Wakefield - 241 St 2",
  "Wall St 2-3",
  "Wall St 4-5",
  "West Farms Sq - E Tremont Av 2-5",
  "Westchester Sq - E Tremont Av 6",
  "Whitehall St R-W",
  "Whitlock Av 6",
  "Wilson Av L",
  "Winthrop St 2-5",
  "Woodhaven Blvd J",
  "Woodhaven Blvd M-R",
  "Woodlawn 4",
  "Woodside - 61 St 7",
  "World Trade Center E",
  "York St F",
  "Zerega Av 6"
]

export default stationNames;
